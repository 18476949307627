<template>
  <div v-if="isShow" ref="colorRef" class="color-picker-modal">
    <div class="overlay" @click.prevent="close"></div>
    <chrome
      v-model="iValue"
      :style="{ top: `${topOffset}px`, left: `${leftOffset}px` }"
    />
    <button
      class="btn-clear"
      :style="{ top: `${topOffset + 186}px`, left: `${leftOffset + 170}px` }"
      @click="resetColor"
    >
      Clear
    </button>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  components: { Chrome },

  props: {
    value: {
      required: false,
      type: [String],
      default: '',
    },
  },

  data() {
    return {
      isShow: true,
      topOffset: 0,
      leftOffset: 0,
    }
  },

  computed: {
    iValue: {
      get() {
        return this.value || '#FFFFFF'
      },
      set(newValue) {
        this.$emit('input', newValue.hex)
      },
    },
  },

  mounted() {
    let offset = this.$refs.colorRef.parentElement.getBoundingClientRect()
    this.topOffset = offset.top + 70
    this.leftOffset = offset.left
  },

  methods: {
    close() {
      this.isShow = false
      this.$emit('closed')
    },

    resetColor() {
      this.$emit('input', null)
    },
  },
}
</script>

<style lang="scss">
.color-picker-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  .vc-chrome-toggle-btn {
    display: none;
  }
  .vc-input__label {
    display: none !important;
  }
  .overlay {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
  }
  .vc-chrome {
    position: absolute;
  }
  .vc-input__input {
    max-width: 140px;
  }
  .btn-clear {
    position: absolute;
    background: #f44250;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
  }
}
</style>
